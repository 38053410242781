import React, { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [startGlowing, setStartGlowing] = useState(false);

  useEffect(() => {
    // Start glowing effect after the button fades in (6s delay)
    const timer = setTimeout(() => setStartGlowing(true), 6000);
    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  const handleJoinWaitlist = () => {
    window.location.href = "mailto:tej@busyweek.in";
  };

  const createStars = () => {
    const stars = [];
    for (let i = 0; i < 30; i++) {
      const directionClass = Math.random() < 0.5 ? "star-left" : "star-right";
      stars.push(
        <div
          key={i}
          className={`star ${directionClass}`}
          style={{
            left: `${Math.random() * 100}%`,
            top: `-20%`,
            animationDelay: `${Math.random() * 5}s`,
            animationDuration: `${6 + Math.random() * 4}s`,
          }}
        ></div>
      );
    }
    return stars;
  };

  return (
    <div className="app">
      {/* Shooting Stars Background */}
      <div className="shooting-stars">{createStars()}</div>

      {/* Logo */}
      <div className="logo-text">Busy Week AI</div>

      {/* New Smaller Join Waitlist Button at Top Right */}
      <button className="waitlist-button-small" onClick={handleJoinWaitlist}>
        Waitlist
      </button>

      {/* Main Content */}
      <div className="content">
        {/* Apply glowing class dynamically */}
        <h1
          className={`title line-1 ${startGlowing ? "line-1-glowing" : ""}`}
        >
          Intelligence That Plans, You That Wins.
        </h1>
        <h2 className="title line-2">Powered by AI Agents</h2>
        <p className="subtitle line-3">
          Your calendar, reimagined. Let AI organize your week so you can
          prioritize what truly matters.
        </p>
        <button
          className="waitlist-button fade-in-button"
          onClick={handleJoinWaitlist}
        >
          Join Waitlist
        </button>
      </div>
    </div>
  );
}

export default App;
